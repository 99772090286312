import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialauth from 'vue-social-auth';
import Notifications from 'vue-notification';
import twemoji from 'twemoji';
import VueGtm from 'vue-gtm';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// DonblerAPI Axios設定
const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});
Vue.use(VueAxios, axiosBase);

// 画面通知設定
Vue.use(Notifications);

// ソーシャルログイン設定
Vue.use(VueSocialauth, {
  providers: {
    github: {
      clientId: process.env.VUE_APP_GITHUB_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GITHUB_URL,
    },
    twitter: {
      clientId: process.env.VUE_APP_TWITTER_CLIENT_ID,
      redirectUri: process.env.VUE_APP_TWITTER_URL,
    },
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_URL,
    },
  },
});

// v-emoji登録
Vue.directive('emoji', {
  inserted(element) {
    const el = element;
    el.innerHTML = twemoji.parse(element.innerHTML, { base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' });
  },
});

// GA読み込み
if (process.env.VUE_APP_HOST !== 'https://dev.donbler.com') Vue.use(VueGtm, { id: process.env.VUE_APP_GTM_ID });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';

Vue.use(Vuex);

/* AxiosBaseUrl管理 */
const axiosBaseMaterial = axios.create({
  baseURL: process.env.VUE_APP_S3_MATERIAL_URL,
});

const axiosBaseApi = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: true,
  responseType: 'json',
});

export default new Vuex.Store({
  state: {
    user: {
      login: false,
      signupTutorial: false,
      token: '',
      id: '',
      oauthPlatFormId: [],
      profile: {
        name: '',
      },
      send_email_magazine: false,
      first_course_select_status: false,
    },
    analysis: {
      signupParams: {
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_content: null,
        utm_term: null,
      },
    },
    study: {
      courseInfo: {
        id: '',
        categoryId: [],
        name: '',
        description: '',
        lang: '',
        logo: '',
        period: '',
        subscriptionCancelFlg: false,
      },
      lessonInfo: [],
      progressInfo: [],
    },
    ticket: [],
  },
  mutations: {
    // ログイン時取得したユーザー情報の保存
    saveUserInfo(state, userInfo) {
      const vuex = state;
      vuex.user.id = userInfo.data.user.id;
      vuex.user.login = true;
      vuex.user.token = userInfo.data.token;
      vuex.user.oauthPlatFormId = userInfo.data.user.oauthPlatFormId;
      vuex.user.profile.name = userInfo.data.user.name;
      vuex.user.send_email_magazine = userInfo.data.user.send_email_magazine;
      // #280 初回コース選択済みor既存ユーザーならtrueになる
      vuex.user.first_course_select_status = userInfo.data.user.first_course_select_status === 1;

      if (userInfo.data.user.created_at === userInfo.data.user.updated_at) {
        vuex.user.signupTutorial = true;
      } else {
        vuex.user.signupTutorial = false;
      }
    },

    // ログイン時取得したユーザー情報の削除
    deleteUserInfo(state) {
      const vuex = state;
      vuex.user = {
        login: false,
        token: '',
        id: '',
        oauthPlatFormId: [],
        profile: {
          name: '',
        },
        send_email_magazine: false,
        first_course_select_status: false,
        analysis: {
          signupParams: {
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_content: null,
            utm_term: null,
          },
        },
      };
      vuex.study = {
        courseInfo: {
          id: '',
          categoryId: [],
          name: '',
          description: '',
          lang: '',
          logo: '',
          period: '',
          subscriptionCancelFlg: false,
        },
        lessonInfo: [],
        progressInfo: [],
      };
      vuex.ticket = [];
    },

    // ユーザー情報の更新
    updateUserInfo(state, userInfo) {
      const vuex = state;
      vuex.user.profile.name = userInfo.data.name;
      // #280 初回コース選択済みならtrueになる
      vuex.user.first_course_select_status = userInfo.data.first_course_select_status === 1;
    },

    // 受講中のコース情報を格納
    saveCourseInfo(state, courseInfo) {
      const { study } = state;
      study.courseInfo.id = courseInfo.data.id;
      study.courseInfo.categoryId = courseInfo.data.category_id;
      study.courseInfo.name = courseInfo.data.name;
      study.courseInfo.description = courseInfo.data.description;
      study.courseInfo.lang = courseInfo.data.language;
      study.courseInfo.logo = courseInfo.data.logo;
      study.courseInfo.period = courseInfo.data.current_period_end;
      study.courseInfo.subscriptionCancelFlg = courseInfo.data.cancel_at_period_end;
    },

    // コースを構成するレッスン情報を保存
    saveLessonInfo(state, lessonInfo) {
      const { study } = state;
      study.lessonInfo = lessonInfo;
    },

    // 学習進捗を保存
    saveProgress(state, progressList) {
      const { study } = state;
      study.progressInfo = progressList;
    },

    // 学習進捗を追加
    addProgress(state, progress) {
      const { study } = state;
      study.progressInfo.push(progress);
    },

    // 学習進捗を削除
    deleteProgress(state, progress) {
      const { study } = state;
      const deleted = study.progressInfo.filter(
        (v) => v.categoryId !== progress.categoryId
        || v.lessonId !== progress.lessonId
        || v.chapterId !== progress.chapterId,
      );
      study.progressInfo = deleted;
    },

    // メルマガ購読の開始
    saveNotification(state) {
      state.user.send_email_magazine = true;
    },
    // メルマガ購読の終了
    deleteNotification(state) {
      state.user.send_email_magazine = false;
    },

    setFalseSignupTutorial(state) {
      state.user.signupTutorial = false;
    },

    // 質問チケット情報を保存
    saveTicket(state, ticketInfo) {
      const vuex = state;
      vuex.ticket = ticketInfo;
    },

    // 使用したチケット情報を更新
    saveUseTicket(state, uploadData) {
      const index = state.ticket.findIndex((e) => e.id === uploadData.ticket_id);
      state.ticket[index].user_tickets_count -= uploadData.count;
    },

    // 使用したチケット情報を更新
    // saveUseTicket(state, uploadDatas) {
    //   uploadDatas.forEach((uploadData) => {
    //     const index = state.ticket.findIndex((e) => e.id === uploadData.ticket_id);
    //     state.ticket[index].user_tickets_count -= uploadData.count;
    //   })
    // },

    // signup時のanalysis情報のセット
    setSignupParam(state, [paramContent, paramName]) {
      state.analysis.signupParams[paramName] = paramContent;
    },
  },
  actions: {
    /* user系 */
    // ログイン、ユーザー情報取得
    async execLogin(context, userInfo) {
      try {
        const vuex = context;
        const res = await axiosBaseApi.get(`/api/login/${userInfo.provider}/callback`, { params: userInfo.payload });
        vuex.commit('saveUserInfo', res);
        return true;
      } catch (e) {
        return false;
      }
    },

    // メールアドレス会員登録、ユーザー情報取得
    async emailExecLogin(context, userInfo) {
      try {
        const vuex = context;
        const res = await axiosBaseApi.post('/api/login', {
          email: userInfo.email,
          password: userInfo.password,
        });
        vuex.commit('saveUserInfo', res);
        return true;
      } catch (e) {
        if (e.response?.status === 400) {
          throw new Error(e.response.data.error);
        } else {
          throw new Error('エラーが発生しました。しばらく時間をおいてお試しください');
        }
      }
    },

    // メールアドレス会員登録、ユーザー情報取得
    async emailExecVerified(context, userInfo) {
      try {
        await axiosBaseApi.post('/api/login/verified', {
          name: userInfo.name,
          email: userInfo.email,
          password: userInfo.password,
          password_confirmation: userInfo.confirmPassword,
        });
        return true;
      } catch (e) {
        if (e.response?.status === 400) {
          throw new Error(e.response.data.error);
        } else {
          throw new Error('エラーが発生しました。しばらく時間をおいてお試しください');
        }
      }
    },

    async emailExecRegister(context, auth) {
      try {
        const vuex = context;
        const res = await axiosBaseApi.post('/api/login/register', {
          key: auth.key,
          accessToken: auth.accessToken,
        });
        vuex.commit('saveUserInfo', res);
        return true;
      } catch (e) {
        if (e.response?.status === 400) {
          throw new Error(e.response.data.error);
        } else {
          throw new Error('エラーが発生しました。しばらく時間をおいてお試しください');
        }
      }
    },

    // ログアウト
    async execLogout(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.put('/api/auth/user/logout');
        vuex.commit('deleteUserInfo');
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // ユーザー削除
    async deleteUser(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.delete('/api/auth/user');
        vuex.commit('deleteUserInfo');
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // ユーザー情報更新
    async updateUser(context, uploadData) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        const res = await axiosBaseApi.put('/api/auth/user', uploadData);
        vuex.commit('updateUserInfo', res);
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // ユーザーメールアドレス変更
    async changeEmail(context, email) {
      try {
        const vuex = context;
        const newEmail = { email };
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.put('/api/auth/user/email', newEmail);
        return true;
      } catch (e) {
        let result = false;
        if (e.response?.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          result = e.response.status;
        } else if (e.response?.data.error) {
          throw new Error(e.response.data.error);
        } else if (e.response?.data.errors.email[0]) {
          throw new Error(e.response.data.errors.email[0]);
        } else {
          throw new Error('エラーが発生しました。しばらく時間をおいてお試しください');
        }
        return result;
      }
    },

    // ユーザーパスワード変更
    async changePassword(context, changePasswordInfo) {
      try {
        const vuex = context;

        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.put('/api/auth/user/password', changePasswordInfo);
        return true;
      } catch (e) {
        let result = false;
        if (e.response?.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          result = e.response.status;
        } else if (e.response?.data.error) {
          throw new Error(e.response.data.error);
        } else {
          throw new Error('エラーが発生しました。しばらく時間をおいてお試しください');
        }
        return result;
      }
    },

    /* study */
    // 受講中のコース情報を取得
    async loadCourseInfo(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        const res = await axiosBaseApi.get('/api/auth/course');
        vuex.commit('saveCourseInfo', res);
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // コースを構成するレッスン情報を取得
    async loadLessonInfo(context) {
      try {
        const vuex = context;
        const lessonInfo = [];
        const { categoryId } = vuex.state.study.courseInfo;

        for (let i = 0; i < categoryId.length; i += 1) {
          const config = await axiosBaseMaterial.get(`/educationContents/${categoryId[i]}/config.json`);
          for (let j = 0; j < config.data.length; j += 1) {
            lessonInfo.push(config.data[j]);
          }
        }

        vuex.commit('saveLessonInfo', lessonInfo);
        return true;
      } catch (e) {
        return false;
      }
    },

    // チャプターの学習進捗の読み込み
    async loadProgress(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        const res = await axiosBaseApi.get('/api/auth/course/progress');
        const progressList = res.data.map((v) => ({
          categoryId: v.category_id,
          lessonId: v.lesson_id,
          chapterId: v.chapter_id,
        }));
        vuex.commit('saveProgress', progressList);
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // チャプターの学習進捗の追加
    async createProgress(context, bodyData) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.post('/api/auth/course/progress', bodyData);
        vuex.commit('addProgress', {
          categoryId: bodyData.category_id,
          lessonId: bodyData.lesson_id,
          chapterId: bodyData.chapter_id,
        });
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    // チャプターの学習進捗の削除
    async deleteProgress(context, bodyData) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.delete('/api/auth/course/progress', { data: bodyData });
        vuex.commit('deleteProgress', {
          categoryId: bodyData.category_id,
          lessonId: bodyData.lesson_id,
          chapterId: bodyData.chapter_id,
        });
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },

    /* ticket */
    // 購入したチケット情報を取得
    async loadTicketInfo(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        const { data } = await axiosBaseApi.get('/api/auth/tickets');
        vuex.commit('saveTicket', data);
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },
    async useTicket(context, uploadData) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.put('/api/auth/tickets', uploadData);
        vuex.commit('saveUseTicket', uploadData);
        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }
        return false;
      }
    },
    /* notification */
    // メールマガジン購読開始
    async startMailMagazine(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.post('/api/auth/user/emailmagazine');
        // vuexのstateにデータを保持する処理, mutation
        vuex.commit('saveNotification');

        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }

        return false;
      }
    },

    // メールマガジン購読の終了
    async endMailMagazine(context) {
      try {
        const vuex = context;
        axiosBaseApi.defaults.headers.common.Authorization = `Bearer ${vuex.state.user.token}`;
        await axiosBaseApi.delete('/api/auth/user/emailmagazine');
        // vuexのstateにデータを保持する処理, mutation
        vuex.commit('deleteNotification');

        return true;
      } catch (e) {
        if (e.response.status === 401) {
          const vuex = context;
          vuex.commit('deleteUserInfo');
          return e.response.status;
        }

        return false;
      }
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: process.env.VUE_APP_VUEX_KEY,
  })],
});

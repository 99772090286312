import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index.js';
// import Sphone from '../views/Sphone.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Top',
    component: () => import('../views/Top.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue'),
    meta: {
      requireAuth: true,
      spPage: true,
    },
  },
  {
    path: '/study/:categoryId(\\d+)/:lessonId(\\d+)/:chapterId(\\d+)',
    name: 'Study',
    component: () => import('../views/Study.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal.vue'),
    meta: {
      title: 'アカウント削除',
      requireAuth: true,
    },
  },
  {
    path: '/coursewithdrawal',
    name: 'CourseWithdrawal',
    component: () => import('../views/CourseWithdrawal.vue'),
    meta: {
      title: 'コース解約',
      requireAuth: true,
    },
  },
  {
    path: '/settings/account',
    name: 'SettingAccount',
    component: () => import('../views/settings/Account.vue'),
    meta: {
      title: 'アカウント',
      requireAuth: true,
    },
  },
  {
    path: '/settings/courses',
    name: 'SettingCourses',
    component: () => import('../views/settings/Courses.vue'),
    meta: {
      title: 'コース',
      requireAuth: true,
    },
  },
  // {
  //   path: '/settings/tickets/:ticketName(question|appoint)',
  //   name: 'SettingTickets',
  //   component: () => import('../views/settings/Tickets.vue'),
  //   meta: {
  //     title: 'チケット',
  //     requireAuth: true,
  //   },
  // },
  {
    path: '/settings/email',
    name: 'SettingEmail',
    component: () => import('../views/settings/Email.vue'),
    meta: {
      title: 'メールアドレス変更',
      requireAuth: true,
    },
  },
  {
    path: '/settings/password',
    name: 'SettingPassword',
    component: () => import('../views/settings/Password.vue'),
    meta: {
      title: 'パスワード変更',
      requireAuth: true,
    },
  },
  {
    path: '/settings/notification',
    name: 'SettingNotification',
    component: () => import('../views/settings/Notification.vue'),
    meta: {
      title: '通知',
      requireAuth: true,
    },
  },
  {
    path: '/ticket/:ticketName(question|question-appoint)',
    name: 'TicketQuestion',
    component: () => import('../views/tickets/Question.vue'),
    meta: {
      title: '質問チケット使用',
      requireAuth: true,
    },
  },
  {
    path: '/ticket/complete',
    name: 'TicketComplete',
    component: () => import('../views/tickets/Complete.vue'),
    meta: {
      title: 'チケット使用完了',
      requireAuth: true,
    },
  },
  {
    path: '/authsetting',
    name: 'AuthSetting',
    component: () => import('../views/AuthSetting.vue'),
    meta: {
      title: 'ログイン設定',
      requireAuth: false,
    },
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      title: 'パスワードリセット',
      requireAuth: false,
    },
  },
  {
    path: '/login/github/callback',
    component: () => import('../views/Callback.vue'),
  },
  {
    path: '/login/google/callback',
    component: () => import('../views/Callback.vue'),
  },
  {
    path: '/login/twitter/callback',
    component: () => import('../views/Top.vue'),
  },
  // {
  //   path: '/sphone',
  //   name: 'Sphone',
  //   component: Sphone,
  // },

  // SP検証用
  {
    path: '/sp/dashboard',
    name: 'SpDashboard',
    component: () => import('../views/sp/Dashboard.vue'),
    meta: {
      requireAuth: true,
      spPage: true,
    },
  },
  {
    path: '/sp/study/:categoryId(\\d+)/:lessonId(\\d+)/:chapterId(\\d+)',
    name: 'SpStudy',
    component: () => import('../views/sp/Study.vue'),
    meta: {
      requireAuth: true,
      spPage: true,
    },
  },
  {
    path: '/sp/error',
    name: 'SpError',
    component: () => import('../views/sp/Error.vue'),
    meta: {
      title: 'エラー',
    },
  },
  {
    path: '/sp/notfound',
    name: 'SpNotFound',
    component: () => import('../views/sp/Error.vue'),
    meta: {
      title: 'ページが見つかりません',
    },
  },
  //

  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      title: 'エラー',
    },
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import('../views/Error.vue'),
    meta: {
      title: 'ページが見つかりません',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  function setPageTitle(pageTitle) {
    document.title = `${pageTitle} | サブスク型オンラインプログラミングスクールDonbler(ドンブラー)`;
  }

  function judgeSphone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0) && navigator.userAgent.indexOf('Mobile') > 0;
  }
  // チャネルトーク除外
  if (window.ChannelIO && to.name !== 'Top') {
    // eslint-disable-next-line no-undef
    ChannelIO('shutdown', {
      pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
    });
  }

  // title動的切り替え
  if (to.meta && to.meta.title && to.meta.title) {
    setPageTitle(to.meta.title);
  } else if (to.meta.title === undefined) {
    setPageTitle('Donbler(ドンブラー)');
  }

  // PC向けルーティング
  if (!judgeSphone()) {
    if (to.matched.some((page) => page.meta.requireAuth) && !Store.state.user.login) {
      // ログイン必須ページか判定
      next({ name: 'Top' });
    } else if (Store.state.study.courseInfo.id === 1
      && Store.state.user.first_course_select_status === false && Store.state.user.login && to.name !== 'Welcome') {
      // #280 コース未選択の場合、ウェルカムページへ飛ばす
      next({ name: 'Welcome' });
    } else if (Store.state.user.first_course_select_status === true && Store.state.user.login && to.name === 'Welcome') {
      // #280 既存ユーザーに`/welcome`を直叩きさせない
      next({ name: 'Dashboard' });
    } else if (Store.state.user.login && to.name === 'Top') {
      // ログイン済みでトップページへアクセス不可
      next({ name: 'Dashboard' });
    } else if (to.name?.match(/^Setting(Email|Password)$/) && Store.state.user.oauthPlatFormId?.indexOf(4) === -1) {
      // 特定のルートはメールアドレスログインの人しか入れないようにする
      next({ path: '/notfound' });
    } else {
      next();
    }
  }

  // SP向けルーティング
  if (judgeSphone()) {
    if (to.matched.some((page) => page.meta.requireAuth) && !Store.state.user.login) {
      // ログイン必須ページか判定
      next({ name: 'Top' });
    } else if (Store.state.study.courseInfo.id === 1
      && Store.state.user.first_course_select_status === false && Store.state.user.login && to.name !== 'Welcome') {
      // #280 コース未選択の場合、ウェルカムページへ飛ばす
      next({ name: 'Welcome' });
    } else if (Store.state.user.first_course_select_status === true && Store.state.user.login && to.name === 'Welcome') {
      // #280 既存ユーザーに`/welcome`を直叩きさせない
      next({ name: 'SpDashboard' });
    } else if (Store.state.user.login && to.name === 'Top') {
      // ログイン済みでトップページへアクセス不可
      next({ name: 'SpDashboard' });
    } else if (Store.state.user.login && to.matched.some((page) => !page.meta.spPage)) {
      // ログイン済みでスマホ表示対象のページでない
      next({ name: 'SpDashboard' });
    } else {
      next();
    }
  }

  // LP画面で時間経過とともにモーダルを表示する機能で利用(ログアウトと他のページからの遷移を判別する)
  router.referrer = from;
});
export default router;
